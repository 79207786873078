import React from 'react'
import { Link } from 'gatsby'

import logo from '../../static/img/logo_agau.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="content">
          <img
            src={logo}
            alt="Agau Edelmetalle Agentur Jena"
            style={{ width: '10em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter footer-info">
              <span>© Copyright 2019 | </span>
              <Link to={"/impressum"}>Impressum</Link>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
